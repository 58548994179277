import { themeColorEnum, themeEnum, sysTypeEnum } from '_e/index'
/**
 * 应用程序store 模块
 */
export default {
    state: {
        isPlatform: process.env.VUE_APP_MODE === sysTypeEnum.PLATFORM.key,
        theme: themeEnum.LIGHT.key,
        themeColor: themeColorEnum.LIGHT.value,
    },
    mutations: {
        // 设置主题
        setTheme (state, theme) {
            state.theme = theme
            if (theme === themeEnum.DARK.key) {
                state.themeColor = themeColorEnum.DARK.value
            } else {
                state.themeColor = themeColorEnum.LIGHT.value
            }
        },
    },
    actions: {
        /**
         * 设置主题
         * @param context
         * @param theme
         */
        setTheme (context, theme) {
            context.commit('setTheme', theme)
        }
    }
}
