var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "error-tip animate__animated animate__fadeInDown animate__fadeInUp"
    },
    [
      _vm._v(" 网络错误(" + _vm._s(_vm.count) + ")秒后自动关闭 "),
      _c("Icon", {
        staticClass: "error-tip-close",
        attrs: { type: "md-close", size: "20" },
        on: { click: _vm.handleCloseTip }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }