import Vue from 'vue'
import NetErrorTip from '_c/netErrorTip/NetErrorTip'
let instance = null
export function getComponents () {
  instance = new Vue({
    render (h) {
      return h(NetErrorTip, {
        on: {
          close: () => {
            document.body.removeChild(this.$el)
            this.$destroy()
            instance = null
          }
        }
      })
    }
  })
  const el = instance.$mount().$el
  document.body.appendChild(el)
  return instance.$children[0]
}

export default function netErrorTip () {
  if (instance) {
    return instance
  }
  return getComponents()
}
