<template>
  <div class="error-tip animate__animated animate__fadeInDown animate__fadeInUp">
    网络错误({{count}})秒后自动关闭
    <Icon type="md-close" class="error-tip-close" @click="handleCloseTip" size="20"/>
  </div>
</template>

<script>
export default {
  name: 'NetErrorTip',
  data () {
    return {
      count: 10
    }
  },
  mounted () {
    const timer = window.setInterval(() => {
      this.count--
      if (this.count === 0) {
        window.clearTimeout(timer)
        this.handleClose()
      }
    }, 1000)
    this.$on('hook:beforeDestroy', () => {
      window.clearTimeout(timer)
    })
  },
  methods: {
    handleCloseTip () {
      this.handleClose()
    },
    handleClose () {
      this.$emit('close')
    }
  },
}
</script>

<style scoped>
  .error-tip{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 40px;
    background-color:rgba(255,0,0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .error-tip-close{
    position: absolute;
    cursor: pointer;
    right: 10px;
  }
</style>
