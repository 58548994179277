import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import {
    cacheStorage
} from '@/utils'
import netErrorTip from '_c/netErrorTip'
import bus from '@/utils/vueBus'

axios.defaults.timeout = 20000
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

axios.interceptors.request.use(function (config) {
    if (store.state.auth) {
        let auth = store.state.auth.token
        if (auth) {
            config.headers.token = auth
        }
        config.headers.merchantId = store.state.auth.merchantId
    }

    let userInfo = cacheStorage.getItem('user')

    if (userInfo) {
        userInfo = JSON.parse(userInfo)
        config.headers.userId = userInfo.id
    }

    // 请求路径若与baseURL不同，则需加上{{charge-evcs}}，才能更换url
    let otherSystem = '{{charge-evcs}}'
    if (config.url.indexOf(otherSystem) !== -1) {
        let keyIndex = config.url.indexOf('}}')
        let replaceUrl = config.url.substring(keyIndex + 3)
        config.url = process.env.VUE_APP_CHARGE_EVCS_URL + replaceUrl
        console.log('改变后的', config.url)
        bus.$emit('requestUrl', config.url)
    }
    return config
}, function (error) {
    return Promise.reject(error)
})
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    let data = response.data || response
    const {
        verifycodesession,
        token,
        hasauth
    } = response.headers
    if (hasauth) {
        sessionStorage.setItem('hasauth', hasauth)
        if (sessionStorage.getItem('hasauth')) {
            sessionStorage.setItem('orderDetails', hasauth)
        }
    }
    if (token) {
        store.dispatch('setToken', token)
    }
    if (response.data.code === 10001) {
        cacheStorage.clear()
        Vue.prototype.$Notice.error({
            title: '登录信息过期，请重新登录',
            desc: response.data.message
        })
        window.location.href = location.href.split('?')[0]
        window.location.reload()
    }
    // 登录验证码 10001
    if (verifycodesession) {
        cacheStorage.setItem(process.env.VUE_APP_VERIFY_CODE_KEY, verifycodesession)
    }
    return Promise.resolve(data)
}, function (error) {
    // 如果存在 error.response 为后台错误，在不同的状态码做不同的操作
    // 否则为网络错误 todo

    if (error.response) {
        if (process.env.NODE_ENV === 'development') {
            const {
                response
            } = error
            Vue.prototype.$Notice.error({
                title: response.status,
                desc: response.data.message
            })
        }
    } else {
        netErrorTip()
    }
    return Promise.reject(error)
})

Vue.prototype.$http = axios
export default axios
