export const forEach = (arr, fn) => {
    if (!arr.length || !fn) return
    let i = -1
    let len = arr.length
    while (++i < len) {
        let item = arr[i]
        fn(item, i, arr)
    }
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
    return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
    if (document.addEventListener) {
        return function (element, event, handler) {
            if (element && event && handler) {
                element.addEventListener(event, handler, false)
            }
        }
    } else {
        return function (element, event, handler) {
            if (element && event && handler) {
                element.attachEvent('on' + event, handler)
            }
        }
    }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
    if (document.removeEventListener) {
        return function (element, event, handler) {
            if (element && event) {
                element.removeEventListener(event, handler, false)
            }
        }
    } else {
        return function (element, event, handler) {
            if (element && event) {
                element.detachEvent('on' + event, handler)
            }
        }
    }
})()

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
    const keysArr1 = Object.keys(obj1)
    const keysArr2 = Object.keys(obj2)
    if (keysArr1.length !== keysArr2.length) {
        return false
    } else if (keysArr1.length === 0 && keysArr2.length === 0) {
        return true
    } else {
        // eslint-disable-next-line eqeqeq
        return !keysArr1.some(key => obj1[key] != obj2[key])
    }
}

export const hasChild = (item) => {
    return item.children && item.children.length !== 0
}

/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array}
 */
export const getMenuByRouter = (list) => {
    let res = []
    forEach(list, item => {
        if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
            let obj = {
                icon: (item.meta && item.meta.icon) || '',
                name: item.name,
                meta: item.meta
            }
            if ((hasChild(item) || (item.meta && item.meta.showAlways))) {
                obj.children = getMenuByRouter(item.children)
            }
            if (item.meta && item.meta.href) obj.href = item.meta.href
            res.push(obj)
        }
    })
    return res
}

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
export const getBreadCrumbList = (route, homeRoute) => {
    let homeItem = {
        ...homeRoute,
        icon: homeRoute.meta ? homeRoute.meta.icon : ''
    }
    let routeMetched = route.matched
    if (routeMetched.some(item => item.name === homeRoute.name)) return [homeItem]
    let res = routeMetched.filter(item => {
        return item.meta === undefined || !item.meta.hideInBread
    }).map(item => {
        let meta = {
            ...item.meta
        }
        if (meta.title && typeof meta.title === 'function') {
            meta.__titleIsFunction__ = true
            meta.title = meta.title(route)
        }
        let obj = {
            icon: (item.meta && item.meta.icon) || '',
            name: item.name,
            meta: meta
        }
        return obj
    })
    res = res.filter(item => {
        return !item.meta.hideInMenu
    })
    return [{
        ...homeItem,
        to: homeRoute.path
    }, ...res]
}

export const getRouteTitleHandled = (route) => {
    let router = {
        ...route
    }
    let meta = {
        ...route.meta
    }
    let title = ''
    if (meta.title) {
        if (typeof meta.title === 'function') {
            meta.__titleIsFunction__ = true
            title = meta.title(router)
        } else {
            title = meta.title
        }
    }
    meta.title = title
    router.meta = meta
    return router
}

export const showTitle = (item, vm) => {
    let {
        title,
        __titleIsFunction__
    } = item.meta
    if (!title) return
    return (item.meta && item.meta.title) || item.name
}

/**
 * @description 本地存储和获取标签导航列表
 */
export const setTagNavListInLocalstorage = list => {
    sessionStorage.setItem(process.env.VUE_APP_TAG_NAV_LIST, JSON.stringify(list))
}
/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTagNavListFromLocalstorage = () => {
    const list = window.sessionStorage.getItem(process.env.VUE_APP_TAG_NAV_LIST)
    return list ? JSON.parse(list) : []
}

/**
 * @param {Array} routers 路由列表数组
 * @param homeName
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = (routers, homeName) => {
    let i = -1
    let len = routers.length
    let homeRoute = {}
    while (++i < len) {
        let item = routers[i]
        if (item.children && item.children.length) {
            let res = getHomeRoute(item.children, homeName)
            if (res.name) return res
        } else {
            if (item.name === homeName) homeRoute = item
        }
    }
    return homeRoute
}

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
    const {
        name,
        path,
        meta
    } = newRoute
    let newList = [...list]
    if (newList.findIndex(item => item.name === name) >= 0) {
        return newList
    } else {
        newList.push({
            name,
            path,
            meta
        })
    }
    return newList
}

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
    let res = {}
    if (list.length === 2) {
        res = getHomeRoute(list)
    } else {
        const index = list.findIndex(item => routeEqual(item, route))
        if (index === list.length - 1) {
            res = list[list.length - 2]
        } else {
            res = list[index + 1]
        }
    }
    return res
}

/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
    let i = -1
    while (++i < times) {
        callback(i)
    }
}

export const findNodeUpper = (ele, tag) => {
    if (ele.parentNode) {
        if (ele.parentNode.tagName === tag.toUpperCase()) {
            return ele.parentNode
        } else {
            return findNodeUpper(ele.parentNode, tag)
        }
    }
}

export const findNodeUpperByClasses = (ele, classes) => {
    let parentNode = ele.parentNode
    if (parentNode) {
        let classList = parentNode.classList
        if (classList && classes.every(className => classList.contains(className))) {
            return parentNode
        } else {
            return findNodeUpperByClasses(parentNode, classes)
        }
    }
}

export const findNodeDownward = (ele, tag) => {
    const tagName = tag.toUpperCase()
    if (ele.childNodes.length) {
        let i = -1
        let len = ele.childNodes.length
        while (++i < len) {
            let child = ele.childNodes[i]
            if (child.tagName === tagName) {
                return child
            } else {
                return findNodeDownward(child, tag)
            }
        }
    }
}

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
    const params1 = route1.params || {}
    const params2 = route2.params || {}
    const query1 = route1.query || {}
    const query2 = route2.query || {}
    return (route1.name === route2.name) && objEqual(params1, params2) && objEqual(query1, query2)
}

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 */
export const routeHasExist = (tagNavList, routeItem) => {
    let len = tagNavList.length
    let res = false
    doCustomTimes(len, (index) => {
        if (routeEqual(tagNavList[index], routeItem)) res = true
    })
    return res
}
/**
 * 滚动到顶部
 * @param el
 * @param from
 * @param to
 * @param duration
 * @param endCallback
 */
export const scrollTop = (el, from = 0, to, duration = 500, endCallback) => {
    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = (
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function (callback) {
                return window.setTimeout(callback, 1000 / 60)
            }
        )
    }
    const difference = Math.abs(from - to)
    const step = Math.ceil(difference / duration * 50)

    const scroll = (start, end, step) => {
        if (start === end) {
            endCallback && endCallback()
            return
        }

        let d = (start + step > end) ? end : start + step
        if (start > end) {
            d = (start - step < end) ? end : start - step
        }

        if (el === window) {
            window.scrollTo(d, d)
        } else {
            el.scrollTop = d
        }
        window.requestAnimationFrame(() => scroll(d, end, step))
    }
    scroll(from, to, step)
}

/**
 * 验证手机号码
 * @param phone
 * @returns {boolean}
 */
export function checkPhone(phone) {
    return /^1[3456789]\d{9}$/.test(phone)
}

/**
 * 判断字符串是不是空字符串
 * @param str
 * @returns {boolean|boolean}
 */
export function isEmptyStr(str) {
    return typeof str === 'string' && str.trim() === ''
}

/**
 * 深度复制
 */
export function deepCopy(obj) {
    if (!obj || typeof obj !== 'object') {
        return obj
    }
    let result = null
    if (Object.prototype.toString.call(obj) === '[object Object]') {
        result = {}
        Object.keys(obj).forEach((key) => {
            result[key] = deepCopy(obj[key])
        })
    } else if (Array.isArray(obj)) {
        result = []
        obj.forEach((item) => {
            result.push(deepCopy(item))
        })
    }
    return result
}

/**
 * 防抖
 * @param fun
 * @param time
 * @returns {function(...[*]=)}
 */
export function debounce(fun, time = 500) {
    let timer = null
    return function (...args) {
        window.clearTimeout(timer)
        timer = window.setTimeout(() => {
            // eslint-disable-next-line no-useless-call
            fun.call(this, ...args)
        }, time)
    }
}

/**
 * 节流
 * @param fun
 * @param time
 * @returns {function(...[*]=)}
 */
export function throttle(fun, time = 500) {
    let prepare = false
    return function (...args) {
        if (prepare) {
            return
        }
        prepare = true
        window.setTimeout(() => {
            fun.call(this, ...args)
        }, time)
    }
}

/**
 * 根据枚举的key 属性获取 value 值
 * @param enumObj
 * @param key
 */
export function handleGetEnumValue(enumObj, key) {
    const itemArray = Object.values(enumObj)
    const enumItem = itemArray.find((item) => {
        return item.key === key
    })
    return enumItem ? enumItem.value : ''
}

/**
 * 根据枚举的value 属性获取 key 值
 * @param enumObj
 * @param value
 */
export function handleGetEnumKey(enumObj, value) {
    const itemArray = Object.values(enumObj)
    const enumItem = itemArray.find((item) => {
        return item.value === value
    })
    return enumItem ? enumItem.key : ''
}
/**
 * 时间格式化
 * @param time
 * @param format
 * @returns {string}
 */
export function timeFormat(time, format = 'yyyy-MM-dd hh:mm:ss') {
    const date = new Date(time)
    const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S': date.getMilliseconds()
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return format
}
/**
 * 当前时间加一天
 */
export function newDate(d) {
    d = new Date(d)
    d = +d + 1000 * 60 * 60 * 24
    d = new Date(d)
    let tYear = d.getFullYear()
    let tMonth = d.getMonth() + 1
    let tDate = d.getDate()
    if (tMonth < 10) {
        tMonth = '0' + tMonth
    }
    if (tDate < 10) {
        tDate = '0' + tDate
    }
    return tYear + '-' + tMonth + '-' + tDate
}

export function getDateTime(day) {
    let today = new Date()
    let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
    today.setTime(targetday_milliseconds)
    let tYear = today.getFullYear()
    let tMonth = today.getMonth() + 1
    let tDate = today.getDate()
    if (tMonth < 10) {
        tMonth = '0' + tMonth
    }
    if (tDate < 10) {
    tDate = '0' + tDate
    }
    return tYear + '-' + tMonth + '-' + tDate
}

/**
 * 地址格式化
 * 后端地址格式 省&市&区
 * 前端显示 省/市/区
 * @param address
 */
export function addressFormat(address) {
    if (address) {
        return address.replace(/&/g, '/')
    }
    return address
}

export const cacheStorage = (function () {
    return process.env.NODE_ENV === 'development' ? window.localStorage : window.sessionStorage
})(window)

/**
 * 校验email
 * @param email
 * @returns {boolean}
 */
export function checkEmail(email) {
    return /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(email)
}

/**
 * 价格取分
 * @param money
 */
export function checkMoney(money) {
    money.flatPrice = accMul(money.flatPrice, 100)
    money.flatServerPrice = accMul(money.flatServerPrice, 100)
    money.peakPrice = accMul(money.peakPrice, 100)
    money.peakServerPrice = accMul(money.peakServerPrice, 100)
    money.spikePrice = accMul(money.spikePrice, 100)
    money.valleyPrice = accMul(money.valleyPrice, 100)
    money.valleyServerPrice = accMul(money.valleyServerPrice, 100)
    money.spikeServerPrice = accMul(money.spikeServerPrice, 100)
    return money
}
/**
 * js浮点数乘法
 */
export function accMul(arg1, arg2) {
    let m = 0
    let s1 = arg1.toString()
    let s2 = arg2.toString()
    try {
      m += s1.split('.')[1].length
    } catch (e) {}
    try {
      m += s2.split('.')[1].length
    } catch (e) {}
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}
/**
 * 价格取元
 * @param money
 */
export function backMoney(money) {
    money.flatPrice = money.flatPrice / 100
    money.flatServerPrice = money.flatServerPrice / 100
    money.peakPrice = money.peakPrice / 100
    money.peakServerPrice = money.peakServerPrice / 100
    money.spikePrice = money.spikePrice / 100
    money.spikeServerPrice = money.spikeServerPrice / 100
    money.valleyPrice = money.valleyPrice / 100
    money.valleyServerPrice = money.valleyServerPrice / 100
    return money
}
/**
 * 价格处理
 * @param value
 */
export function dealWithMoney(value) {
    const text = value / 100
    const money = text.toFixed(2)
    return money
}

/**
 * 时间处理
 * @param time
 */
export function dealWithTime(time) {
    let text = ''
    let str = time || 0
    let hours = Math.floor(str / 60).toString()
    let minutes = (str % 60).toString()
    if (str !== '0' && str !== '' && str !== null) {
       text = hours + '时' + (minutes.length < 2 ? '0' + minutes : minutes) + '分'
    } else {
        text = 0
    }
    return text
}
/**
 * 时间列表处理
 * @param timeList
 * @param dataList
 */
export function dealWithTimeList(timeList, dataList) {
    let data = timeList.map((item, index) => {
        if (item[0].split(':')[1] === '59') {
            let num = Number(item[0].split(':')[0]) + 1
            if (num < 10) {
                item[0] = '0' + num + ':00'
            } else {
                item[0] = num + ':00'
            }
        }
         if (item[1].split(':')[1] === '59') {
            if (Number(item[1].split(':')[0]) + 1 === 24) {
                 item[1] = '24:00'
             } else {
                let num = Number(item[1].split(':')[0]) + 1
                if (num < 10) {
                    item[1] = '0' + num + ':00'
                } else {
                    item[1] = num + ':00'
                }
             }
        }
            return {
                timeStart: item[0],
                timeEnd: item[1],
                priceCode: dataList[index]
            }
    })
    return data
}

export function secondsFormat(s) {
    let day = Math.floor(s / (24 * 3600)) // Math.floor()向下取整
    let hour = Math.floor((s - day * 24 * 3600) / 3600)
    let minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60)
    let second = s - day * 24 * 3600 - hour * 3600 - minute * 60
    if (hour) {
      return hour + '时' + minute + '分' + second + '秒'
    } else {
      if (second < 10) {
          return minute + '分' + '0' + second + '秒'
      } else {
        return minute + '分' + second + '秒'
      }
    }
}
