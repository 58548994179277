/**
 * 项目使用的枚举类型
 * 名字后缀使用 Enum命名，key使用全大写
 * KEY 为枚举类型，可以数字，字符串
 * value 为具体的名字
 */
export const changeEnumToMap = function(enumList) {
    let map = {}
    for (let key in enumList) {
        const item = enumList[key]
        map[item.key] = item.name
    }

    return map
}

export const changeEnumToMap2 = function(enumList) {
    let map = {}
    for (let key in enumList) {
        const item = enumList[key]
        map[item.key] = item.value
    }

    return map
}

/**
 * 主题枚举
 */
export const themeEnum = {
    LIGHT: {
        key: 'light',
        value: '高亮'
    },
    DARK: {
        key: 'dark',
        value: '暗黑'
    },
}

/**
 * 表单状态枚举
 */
export const formStatusEnum = {
    ADD: {
        key: 0,
        value: '新增'
    },
    UPDATE: {
        key: 1,
        value: '修改'
    },
    COPY: {
        key: 2,
        value: '复制'
    }
}

/**
 * 主题颜色枚举
 */
export const themeColorEnum = {
    LIGHT: {
        key: 'light',
        value: 'white'
    },
    DARK: {
        key: 'dark',
        value: ' #515a6e'
    },
}

/**
 * 数据显示模式枚举
 */
export const showModeEnum = {
    TABLE_MODE: {
        key: 0,
        value: '表格模式'
    },
    MAP_MODE: {
        key: 1,
        value: '地图模式'
    }
}

/**
 * 登录方式
 */
export const loginTypeEnum = {
    PASSWORD: {
        key: 0,
        value: '账号密码登录'
    },
    AUTH_CODE: {
        key: 1,
        value: '手机验证码登录'
    },
    FORGET_PASSWORD: {
        key: 2,
        value: '忘记密码'
    }
}

/**
 * 菜单类型枚举
 */
export const menuTypeEnum = {
    PLATFORM: {
        key: 0,
        value: '平台'
    },
    MERCHANT: {
        key: 1,
        value: '商家'
    },
}

/**
 * 流水状态枚举，支付状态
 */
export const liushuiMenuList = {
    CREATE: {
        key: -1,
        value: '待支付'
    },
    FAIL: {
        key: 0,
        value: '失败'
    },
    SUCCESS: {
        key: 1,
        value: '成功'
    },
}

/**
 * 流水充值方式枚举
 */
export const liushuiTypeMenuList = {
    MEW: {
        key: 1,
        value: '充值'
    },
    PAY: {
        key: 2,
        value: '支付'
    },
    DRAW: {
        key: 3,
        value: '提现'
    },
}
/**
 * 系统类型枚举
 */
export const sysTypeEnum = {
    PLATFORM: {
        key: 'platform',
        value: '平台'
    },
    MERCHANT: {
        key: 'merchant',
        value: '商家'
    },
}

/**
 * 充电接口枚举
 * @type {{}}
 */
export const pileChargeCoupleEnum = {
    STANDARD: {
        key: 1,
        value: '国标'
    },
    BYD: {
        key: 2,
        value: '比亚迪'
    },
    TESLA: {
        key: 3,
        value: '特斯拉'
    },
    OTHER: {
        key: 4,
        value: '其他'
    },
}

/**
 * 联网状态枚举
 */
export const pileNetworkEnum = {
    IN_CONSTRUCTION: {
        key: 0,
        value: '建设中'
    },
    OFF_LINE: {
        key: 1,
        value: '未联网'
    },
    ON_LINE: {
        key: 2,
        value: '已联网'
    }
}

/**
 * 充电状态枚举
 * @type {{}}
 */
export const pileChargeStatusEnum = {
    CHARGING: {
        key: 1,
        value: '充电中'
    },
    LEISURE: {
        key: 2,
        value: '空闲'
    },
    FAULT: {
        key: 3,
        value: '故障'
    },
    BOOKING: {
        key: 4,
        value: '预约'
    },
    OFF_LINE: {
        key: 5,
        value: '离线'
    },
    DELETE: {
        key: -1,
        value: '废弃'
    },
}

/**
 * 充电类型枚举
 * @type {{}}
 */
export const statioValueTypeEnum = {
    CHARGING: {
        key: 1,
        value: '交流慢充站'
    },
    LEISURE: {
        key: 2,
        value: '直流快充站'
    },
    FAULT: {
        key: 3,
        value: '交直混合站'
    }
}

/**
 * 故障状态枚举
 */
export const pileFaultStatusEnum = {
    NO_FAULT: {
        key: 1,
        value: '提示告警'
    },
    MACHINE_FAULT: {
        key: 2,
        value: '一般告警'
    },
    NET_FAULT: {
        key: 3,
        value: '重要告警'
    },
    SYS_FAULT: {
        key: 4,
        value: '紧急告警'
    },
}
/**
 * 告警状态枚举
 */
export const warnMesgStatusEnum = {
    NO_FAULT: {
        key: 0,
        value: '无故障'
    },
    MACHINE_FAULT: {
        key: 1,
        value: '机器故障'
    },
    NET_FAULT: {
        key: 2,
        value: '网络故障'
    },
    SYS_FAULT: {
        key: 3,
        value: '系统故障'
    },
}

/**
 * 允许预约枚举
 */
export const pileAllowBookEnum = {
    NO_ALLOW: {
        key: 0,
        value: '不允许'
    },
    ALLOW: {
        key: 1,
        value: '允许'
    },
}

/**
 * 充电站类型枚举
 */
export const pileChargeTypeEnum = {
    QUICK_CHARGE: {
        key: 1,
        value: '快充'
    },
    SLOW_CHARGE: {
        key: 2,
        value: '慢充'
    },
}

/**
 * 充电桩运营状态
 * @type {{}}
 */
export const pileServiceStatusEnum = {
    STOP_OPERATING: {
        key: 0,
        value: '停止运营'
    },
    OPERATING: {
        key: 1,
        value: '运作中'
    }
}

/**
 * 运营规则时间
 */
export const chargeStationTimeData = ['0:00-0:30', '0:30-1:00', '1:00-1:30', '1:30-2:00', '2:00-2:30', '2:30-3:00', '3:00-3:30', '3:30-4:00', '4:00-4:30', '4:30-5:00', '5:00-5:30', '5:30-6:00', '6:00-6:30', '6:30-7:00', '7:00-7:30', '7:30-8:00', '8:00-8:30', '8:30-9:00', '9:00-9:30', '9:30-10:00', '10:00-10:30', '10:30-11:00', '11:00-11:30', '11:30-12:00', '12:00-12:30', '12:30-13:00', '13:00-13:30', '13:30-14:00', '14:00-14:30', '14:30-15:00', '15:00-15:30', '15:30-16:00', '16:00-16:30', '16:30-17:00', '17:00-17:30', '17:30-18:00', '18:00-18:30', '18:30-19:00', '19:00-19:30', '19:30-20:00', '20:00-20:30', '20:30-21:00', '21:00-21:30', '21:30-22:00', '22:00-22:30', '22:30-23:00', '23:00-23:30', '23:30-00:00']
export const chargeStationTimeArray = ['0:00', '0:30', '0:30', '1:00', '1:00', '1:30', '1:30', '2:00', '2:00', '2:30', '2:30', '3:00', '3:00', '3:30', '3:30', '4:00', '4:00', '4:30', '4:30', '5:00', '5:00', '5:30', '5:30', '6:00', '6:00', '6:30', '6:30', '7:00', '7:00', '7:30', '7:30', '8:00', '8:00', '8:30', '8:30', '9:00', '9:00', '9:30', '9:30', '10:00', '10:00', '10:30', '10:30', '11:00', '11:00', '11:30', '11:30', '12:00', '12:00', '12:30', '12:30', '13:00', '13:00', '13:30', '13:30', '14:00', '14:00', '14:30', '14:30', '15:00', '15:00', '15:30', '15:30', '16:00', '16:00', '16:30', '16:30', '17:00', '17:00', '17:30', '17:30', '18:00', '18:00', '18:30', '18:30', '19:00', '19:00', '19:30', '19:30', '20:00', '20:00', '20:30', '20:30', '21:00', '21:00', '21:30', '21:30', '22:00', '22:00', '22:30', '22:30', '23:00', '23:00', '23:30', '23:30', '24:00']
//
/**
 * 充电站类型枚举
 */
export const stationChargeTypeEnum = {
    QUICK_CHARGE: {
        key: 1,
        value: '快充'
    },
    SLOW_CHARGE: {
        key: 2,
        value: '慢充'
    },
}

/**
 * 充电站运行类型
 * @type {{}}
 */
export const stationServiceTypeEnum = {
    STAND_ALONE: {
        key: 1,
        value: '公共'
    },
    NETWORK: {
        key: 2,
        value: '个人'
    },
    BUS: {
        key: 3,
        value: '公交'
    },
    GUARDS: {
        key: 4,
        value: '环卫'
    },
    LOGISTICS: {
        key: 5,
        value: '物流'
    },
    TAXI: {
        key: 6,
        value: '出租车'
    },
    OTHER: {
        key: 7,
        value: '其他'
    },
}

/**
 * 站点站服务类型枚举
 * @type {{}}
 */
export const stationServiceListEnum = {
    STORE: {
        key: 1,
        value: '便利店'
    },
    LIGHT_MEAL: {
        key: 2,
        value: '简餐'
    },
    BATHROOM: {
        key: 3,
        value: '卫生间'
    },
    LOUNGE: {
        key: 4,
        value: '休息室'
    },
    CANOPY: {
        key: 5,
        value: '雨棚'
    },
    ONDUTY: {
        key: 6,
        value: '有人值守'
    }
}

/**
 * 站点站建设场所枚举
 * @type {{}}
 */
export const stationConstructionListEnum = {
    AREA: {
        key: 1,
        value: '居民区'
    },
    PUBLIC: {
        key: 2,
        value: '公共机构'
    },
    ENTERPRISE: {
        key: 3,
        value: '企事业单位'
    },
    OFFICE: {
        key: 4,
        value: '写字楼'
    },
    PARK: {
        key: 5,
        value: '工业园区'
    },
    TRANSPORTATION: {
        key: 6,
        value: '交通枢纽'
    },
    CULTURAL: {
        key: 7,
        value: '大型文体设施'
    },
    URBAN: {
        key: 8,
        value: '城市绿地'
    },
    LARGE_PARK: {
        key: 9,
        value: '大型建筑配建停车场'
    },
    STREET: {
        key: 10,
        value: '路边停车位'
    },
    INTERCITY: {
        key: 11,
        value: '城际高速服务区'
    },
    OTHER: {
        key: 255,
        value: '其他'
    }
}

/**
 * 站点站类型枚举
 * @type {{}}
 */
export const stationTypeEnum = {
    HIGHWAY: {
        key: 1,
        value: '交流站'
    },
    CHARGING_PILE_GROUP: {
        key: 2,
        value: '直流站'
    },
    POWER_PLANT: {
        key: 3,
        value: '交直混合站'
    },
}

/**
 * 充电桩状态枚举
 */
export const stationStatusEnum = {
    STOP: {
        key: 0,
        value: '停止运营'
    },
    OPERATING: {
        key: 1,
        value: '运营中'
    },
    BUILDING: {
        key: 2,
        value: '建设中'
    },
    HELPING: {
        key: 3,
        value: '维护中'
    },
    UNKNOWNING: {
        key: 4,
        value: '未知'
    },
}

/**
 * 充电桩状态枚举
 */
export const newPilestationStatusEnum = {
    PLANNING: {
        key: -1,
        value: '断网未注册'
    },
    IN_CONSTRUCTION: {
        key: 1,
        value: '在线允许充电'
    },
    OPERATING: {
        key: 0,
        value: '离线禁止充电'
    },
}

/**
 * 充电桩运营状态
 */
export const serviceStatusEnum = {
    IN_CONSTRUCTION: {
        key: 1,
        value: '运营'
    },
    OPERATING: {
        key: 0,
        value: '非运营'
    },
}

/**
 * 充电桩连接状态
 */
export const connectStatusEnum = {
    OPERATING: {
        key: 0,
        value: '断开'
    },
    IN_CONSTRUCTION: {
        key: 1,
        value: '半连接'
    },
    connect: {
        key: 2,
        value: '连接'
    },
}

/**
 * 充电桩输出接触器状态/电子锁状态
 */
export const outputContactorStatusEnum = {
    OPERATING: {
        key: 0,
        value: '断开'
    },
    IN_CONSTRUCTION: {
        key: 1,
        value: '闭合'
    },
}

/**
 * 充电站是否允许预约枚举
 * @type {{}}
 */
export const stationAllowBookEnum = {
    NO_ALLOW: {
        key: 0,
        value: '不允许'
    },
    ALLOW: {
        key: 1,
        value: '允许'
    },
}

/**
 * 充电站是否对快电开放
 * @type {{}}
 */
export const kuaidianEnum = {
    NO_ALLOW: {
        key: 0,
        value: '不开放'
    },
    ALLOW: {
        key: 1,
        value: '开放'
    },
}

/**
 * 充电站是否上报粤易充
 * @type {{}}
 */
export const yueyichongEnum = {
    NO_ALLOW: {
        key: 0,
        value: '否'
    },
    ALLOW: {
        key: 1,
        value: '是'
    },
}

/**
 * 充电桩设备ID状态枚举
 */
export const cpNoStatusEnum = {
    USE: {
        key: 1,
        value: '已使用'
    },
    NO_USE: {
        key: 0,
        value: '未使用'
    },
}

/**
 * 计费规则类型枚举
 */

export const feeRuleEnum = {
    SPIKE: {
        field: 'spikePrice',
        serverfield: 'spikeServerPrice',
        key: 0,
        value: '尖'
    },
    PEAK: {
        field: 'peakPrice',
        serverfield: 'peakServerPrice',
        key: 1,
        value: '峰'
    },
    FLAT: {
        field: 'flatPrice',
        serverfield: 'flatServerPrice',
        key: 2,
        value: '平'
    },
    VALLEY: {
        field: 'valleyPrice',
        serverfield: 'valleyServerPrice',
        key: 3,
        value: '谷'
    }
}

let feeRuleIntervalValueEnum = {}

function createFeeRuleIntervalEnum() {
    const originFeeRule = 2
    const feeRuleIntervalEnum = {}
    feeRuleIntervalEnum['t0000M0030'] = {
        key: 't0000M0030',
        value: '00:00~00:30'
    }

    feeRuleIntervalValueEnum['t0000M0030'] = originFeeRule

    for (let i = 1; i < 24; i++) {
        const hour = i < 10 ? '0' + i : i
        const preI = i - 1
        const preHour = preI < 10 ? '0' + preI : preI

        feeRuleIntervalEnum[`t${preHour}30M${hour}00`] = {
            key: `t${preHour}30M${hour}00`,
            value: `${preHour}:30~${hour}:00`
        }
        feeRuleIntervalEnum[`t${hour}00M${hour}30`] = {
            key: `t${hour}00M${hour}30`,
            value: `${hour}:00~${hour}:30`
        }

        feeRuleIntervalValueEnum[`t${preHour}30M${hour}00`] = originFeeRule
        feeRuleIntervalValueEnum[`t${hour}00M${hour}30`] = originFeeRule
    }

    feeRuleIntervalEnum['t2330M0000'] = {
        key: 't2330M0000',
        value: '23:30~00:00'
    }

    feeRuleIntervalValueEnum['t2330M0000'] = originFeeRule

    return feeRuleIntervalEnum
}
/**
 * 计费规则分段枚举
 */

export const feeRuleIntervalEnum = createFeeRuleIntervalEnum()

export {
    feeRuleIntervalValueEnum
}

/**
 * 充电桩开放类型
 * @type {{}}
 */
export const chargePileOpenTimeTypeEnum = {
    ALL_OPENING_UP: {
        key: 1,
        value: '全部开放'
    },
    CLOSE: {
        key: 2,
        value: '不开放'
    },
    TIME_OPENING_UP: {
        key: 3,
        value: '分时开放'
    }
}

/**
 * 停车位收费类型
 */
export const parkFreeTypeEnum = {
    FREE: {
        key: 1,
        value: '免费'
    },
    CHARGE: {
        key: 2,
        value: '收费'
    },
    FREE_LIMITED_TIME: {
        key: 3,
        value: '限时免费'
    }
}

/**
 *  充电桩限量类型
 */
export const chargePileOpenNumberTypeEnum = {
    ALL_OPENING_UP: {
        key: 1,
        value: '全部开放'
    },
    OPEN_UP_LIMIT_QUANTITY: {
        key: 2,
        value: '限量开放'
    }
}

/**
 *  团体限量类型
 */
export const memberOpenNumberTypeEnum = {
    ALL_OPENING_UP: {
        key: 1,
        value: '全部开放'
    },
    OPEN_UP_LIMIT_QUANTITY: {
        key: 2,
        value: '限量开放'
    }
}
/**
 * 小票类型枚举
 * @type {{}}
 */
export const tickTypeEnum = {
    VERIFICATION_SHEET: {
        key: 1,
        value: '扫一扫核销'
    },
    PAPER_TICK: {
        key: 2,
        value: '纸质小票'
    }
}

/**
 * 会员证件类型
 * @type {{}}
 */
// 会员证件类型1.身份证 2.驾驶证 3.护照 4.其他
export const certificateTypeEnum = {
    IDENTITY_CARD: {
        key: 1,
        value: '身份证'
    },
    DRIVING_LICENCE: {
        key: 2,
        value: '驾驶证'
    },
    PASSPORT: {
        key: 3,
        value: '护照'
    },
    OTHER: {
        key: 4,
        value: '其它'
    }
}

/**
 * 充电枪状态
 * integer($int32)充电枪状态:0.空闲中,1.已插枪,2.启动中(收到启动命令，未进入充电前),3.充电进行中,4.充电完成后未拔枪,5.预约状态,6.系统故障(不能给汽车充电,故障状态下即使插上充电枪仍然反馈故障状态),7.拔枪
 * @type {{}}
 */
export const chargeGunStatusEnum = {
    IN_THE_FREE: {
        key: 0,
        value: '空闲'
    },
    INSERT_GUN: {
        key: 1,
        value: '插枪'
    },
    LAUNCH: {
        key: 2,
        value: '启动中'
    },
    CHARGING: {
        key: 3,
        value: '充电'
    },
    CHARGE_COMPLETE: {
        key: 4,
        value: '充电完成未拔枪'
    },
    BOOKING: {
        key: 5,
        value: '预约状态'
    },
    SYSTEM_FAILURE: {
        key: 6,
        value: '故障'
    },
    DRAW_GUN: {
        key: 7,
        value: '拔枪'
    },
    ALL_GUN: {
        key: 20,
        value: '全部'
    }
}
/**
 * 会员卡等级
 * integer($int32)会员卡等级 1.青铜 2.白银 3.黄金 4.铂金 5.钻石
 * @type {{}}
 */
export const memberCardLevelEnum = {
    BRONZE: {
        key: 1,
        value: '青铜'
    },
    SILVER: {
        key: 2,
        value: '白银'
    },
    GOLD: {
        key: 3,
        value: '黄金'
    },
    PLATINUM: {
        key: 4,
        value: '铂金'
    },
    DIAMOND: {
        key: 5,
        value: '砖石'
    }
}
/**
 * 故障类型
 * integer($int32)会员卡等级 1.青铜 2.白银 3.黄金 4.铂金 5.钻石
 * @type {{}}
 */
export const repairTypeEnum = {
    BRONZE: {
        key: 0,
        value: '位置描述不准确'
    },
    SILVER: {
        key: 1,
        value: '停车费信息不属实'
    },
    GOLD: {
        key: 2,
        value: '二维码无法识别'
    },
    PLATINUM: {
        key: 3,
        value: '充电桩故障'
    },
    DIAMOND: {
        key: 4,
        value: '车位被占用'
    },
    END: {
        key: 5,
        value: '其他'
    },
}
/**
 * 会员卡状态
 * 会员卡状态 1.正常 2.异常 3. 锁定 4.注销 5.挂失 6.停用
 * @type {{}}
 */
export const memberCardStatusEnum = {
    NORMALITY: {
        key: 1,
        value: '正常'
    },
    EXCEPTION: {
        key: 2,
        value: '异常'
    },
    LOCK: {
        key: 3,
        value: '锁定'
    },
    LOG_OUT: {
        key: 4,
        value: '注销'
    },
    REPORT_LOSS: {
        key: 5,
        value: '挂失'
    },
    STOP: {
        key: 6,
        value: '停用'
    }
}

/**
 * 会员卡类型
 * 会员卡类型 1.验证卡(「验证卡」是指卡内不存钱，使用「验证卡」充电，费用在绑定的会员平台钱包内扣取。) 2.储值卡(「储值卡」是指资金储存于卡内，平台不对卡内资金管理！使用「储值卡」充电时，充电费用由桩体的读卡器扣取，不在会员平台钱包内扣取！) 3.VIN卡(「VIN卡」是指卡内不存钱，「VIN卡」卡号为车辆VIN，可支持插抢即启动充电。) 4.其他卡
 * @type {{}}
 */
export const memberTypeEnum = {
    VALIDATION: {
        key: 1,
        value: '验证卡'
    },
    STORED_CARD: {
        key: 2,
        value: '储蓄卡'
    },
    VIN_CARD: {
        key: 3,
        value: 'VIN卡'
    },
    OTHER: {
        key: 4,
        value: '其它卡'
    }
}

/**
 * 订单状态
 * 0：创建，用户扫码后立即创建一张订单；如果创建即确认，此状态则作为保留状态；
1：预约；
11：待预付；
12：已预付；
13：订单已确认，用户确认充电，充电枪状态检查，具备充电条件，平台将发送激活充电枪的指令，激活充电枪；
20：充电中，充电枪已插入车中，正在进行充电；如果不显示此状态，此状态可作为保留状态；
21：充电成功，充电已经结束；尚未结算；
22：充电失败，充电过程出现问题，未能正常结束；
30：已支付；
31：支付失败；
32：已结算；
41：待评价；
50：订单已作废，订单创建后，用户在进行充电前主动取消，如果创建即确认，此状态则作为保留状态；
51：订单已完成，成功的订单完结状态。已对账；
52：订单失败完成，充电失败、没有成功支付或其他原因而需要完结的订单；
 * @type {{}}
 */
export const orderStatusEnum = {
    ORDER_CREATED: {
        key: 0,
        value: '创建'
    },
    // BOOKING: { key: 1, value: '预约' },
    BOOKING_ING: {
        key: 11,
        value: '待预付'
    },
    BOOKING_END: {
        key: 12,
        value: '已预付'
    },
    // ORDER_CONFIRM: { key: 13, value: '订单已确认' },
    BOOTFAILURE: {
        key: 14,
        value: '启动失败'
    },
    CHARGING: {
        key: 20,
        value: '充电中'
    },
    CHARGE_COMPLETE: {
        key: 21,
        value: '充电完成'
    },
    // CHARGE_FAIL: { key: 22, value: '充电失败' },
    ABNORMAL: {
        key: 24,
        value: '异常订单，未结算'
    },
    CLERT: {
        key: 25,
        value: '结算回滚中'
    },
    PAY_COMPLETE: {
        key: 30,
        value: '支付完成'
    },
    PAY_FAIL: {
        key: 31,
        value: '支付失败'
    },
    END_FAIL: {
        key: 32,
        value: '已结算'
    },
    ING_FAIL: {
        key: 33,
        value: '结算中'
    },
    WAIT_COMMENT: {
        key: 41,
        value: '待评价'
    },
    // ORDER_CANCEL: { key: 50, value: '订单作废' },
    ORDER_COMPLETE: {
        key: 51,
        value: '订单已完成'
    },
    // ORDER_FAIL: { key: 52, value: '订单失败' }
}
/**
 *  导出状态
 */
export const orderExportStatusEnum = {
    EXPORTING: {
        key: 0,
        value: '导出中'
    },
    EXPORT_SUCESS: {
        key: 1,
        value: '导出成功'
    },
    EXPORT_ERROR: {
        key: 2,
        value: '导出失败'
    }
}

/**
 *  订单来源
 */
export const orderSourceType = {
    samll_app: {
        key: 1,
        value: '小程序'
    },
    vin_card: {
        key: 2,
        value: 'VIN卡'
    },
    ver_card: {
        key: 3,
        value: '验证卡'
    },
    count_card: {
        key: 4,
        value: '账户充电'
    },
    interconnection: {
        key: 5,
        value: '互联互通'
    }
}
/**
 *  订单审核状态
 */
export const orderAduitType = {
    samll_app: {
        key: 0,
        value: '待审核'
    },
    vin_card: {
        key: 1,
        value: '审核完成'
    },
    ver_card: {
        key: -1,
        value: '审核失败'
    }
}
/**
 * 会员钱包状态
 * @type {{DISABLE: {value: string, key: number}, NORMAL: {value: string, key: number}}}
 */
export const memberWalletStatusEnum = {
    DISABLE: {
        key: 0,
        value: '禁用'
    },
    NORMAL: {
        key: 1,
        value: '正常'
    }
}

/**
 *  钱包类型
 */
export const walletTypeEnum = {
    // user_wallet: { key: 0, value: '用户钱包' },
    member_wallet: {
        key: 1,
        value: '会员钱包'
    },
    group_wallet: {
        key: 2,
        value: '团体钱包'
    }
}

/**
 *  抵扣类型
 */
export const deductTypeEnum = {
    service_cost: {
        key: 1,
        value: '服务费'
    },
    electricity_cost: {
        key: 2,
        value: '电费'
    },
    all_cost: {
        key: 3,
        value: '所有费用'
    },
}
/**
 *  储值卡使用范围
 */
export const storecarduseEnum = {
    selfsupport_station: {
        key: 1,
        value: '自营充电站'
    },
    appoint_station: {
        key: 2,
        value: '指定充电站'
    },
    all_station: {
        key: 3,
        value: '所有充电站'
    },
}

/**
 *  钱包类型
 */
export const walletEnum = {
    user_wallet: {
        key: 0,
        value: '用户钱包'
    },
    member_wallet: {
        key: 1,
        value: '会员钱包'
    },
    group_wallet: {
        key: 2,
        value: '团体钱包'
    }
}
/**
 *  合作协议状态  0执行中 1待审核 2已结束 3作废
 */
export const agreementStatusEnum = {
    status_ing: {
        key: 0,
        value: '待执行'
    },
    status_wait: {
        key: 1,
        value: '执行中'
    },
    status_end: {
        key: -1,
        value: '已结束'
    },
    status_del: {
        key: 2,
        value: '作废'
    }
}

/**
 *  合作协议结算模式:1按固定价钱分成；2按比例分成
 */
export const protocolStationTypeEnum = {
    status_wait: {
        key: 1,
        value: '按固定价钱分成'
    },
    status_del: {
        key: 2,
        value: '按比例分成'
    }
}

/**
 *  合作协议协议类型; 0运营分成;1商家充电合作 2停车合作 3充电数据共享 4车辆数据采集
 */
export const protocolAgrTypeEnum = {
    YUNYING: {
        key: 0,
        value: '运营分成'
    },
    SHANGJIAHEZUO: {
        key: 1,
        value: '商家充电合作'
    },
    TINFCHEHEZUO: {
        key: 2,
        value: '停车合作'
    },
    CHONGDIANGX: {
        key: 3,
        value: '充电数据共享'
    },
    CHELIANGGX: {
        key: 4,
        value: '车辆数据采集'
    }
}

/**
 *  合作协议状态 -1 结束 0待执行 1执行中 2作废
 */
export const protocolStatusEnum = {
    END: {
        key: -1,
        value: '结束'
    },
    DAIZHIXING: {
        key: 0,
        value: '待执行'
    },
    ZHIXING: {
        key: 1,
        value: '执行中'
    },
    FAIL: {
        key: 2,
        value: '作废'
    }
}

/**
 *  合作协议结算类型:1日结,2月结,3季结,4年结
 */
export const protocolAccountTypeEnum = {
    RIJIE: {
        key: 1,
        value: '日结'
    },
    YUEJIE: {
        key: 2,
        value: '月结'
    },
    JIJIE: {
        key: 3,
        value: '季结'
    },
    NIANJIE: {
        key: 4,
        value: '年结'
    }
}

/**
 *  合作商家状态，0为事业单位；1为外企；2民营企业 3国营企业 4其他"
 */
export const orgTypeEnum = {
    SHIYE: {
        key: 0,
        value: '事业单位'
    },
    WAIQI: {
        key: 1,
        value: '外企'
    },
    MINYING: {
        key: 2,
        value: '民营企业'
    },
    HUOYING: {
        key: 3,
        value: '国营企业'
    },
    OTHER: {
        key: 4,
        value: '其他'
    }
}

/**
 *  合作商家结算周期 0月结 1周结 2日结"
 */
export const billCycleEnum = {
    YUEJIE: {
        key: 0,
        value: '月结'
    },
    ZHOUJIE: {
        key: 1,
        value: '周结'
    },
    RIJIE: {
        key: 2,
        value: '日结'
    }
}

/**
 * 验证卡类型枚举
 * @type {{}}
 */
export const VINCardStatusEnum = {
    NORMAL: {
        key: 1,
        value: '正常'
    },
    EXCEPTION: {
        key: 2,
        value: '异常'
    },
    LOCKING: {
        key: 3,
        value: '锁定'
    },
    REPORT_LOSS: {
        key: 5,
        value: '挂失'
    },
    REPORT_RELOAD: {
        key: 6,
        value: '补卡'
    },
    STOP: {
        key: 7,
        value: '停用'
    }
}
/**
 * 储值卡包状态枚举
 * @type {{}}
 */
export const StoreCardStatusEnum = {
    NORMAL: {
        key: 1,
        value: '正常'
    },
    EXCEPTION: {
        key: 2,
        value: '结束'
    },
}
/**
 * VIN 卡类型枚举
 * @type {{}}
 */
export const VerficationCardStatusEnum = {
    NORMAL: {
        key: 1,
        value: '正常'
    },
    EXCEPTION: {
        key: 2,
        value: '异常'
    },
    STOP: {
        key: 3,
        value: '停用'
    }
}
// 流水状态
export const liushuiTypeEnum = {
    NORMAL: {
        key: -1,
        value: '失败'
    },
    EXCEPTION: {
        key: 0,
        value: '待审核'
    },
    STOP: {
        key: 1,
        value: '成功'
    }
}

// 充电桩计费规则下发状态
export const feeRuleStatusEnum = {
    NORMAL: {
        key: 1,
        value: '已经下发'
    },
    EXCEPTION: {
        key: 2,
        value: '未下发'
    }
}

// 充电桩枪编辑新增枚举
// 接口类型
export const connectorTypeEnum = {
    HOUSE_SOCKET: {
        key: 1,
        value: '家用插座'
    },
    COMMUNICATE_SOCKET: {
        key: 2,
        value: '交流接口插座'
    },
    COMMUNICATE_PLUG: {
        key: 3,
        value: '交流接口插头'
    },
    DIRECT_PLUG: {
        key: 4,
        value: '直流接口枪头'
    },
    WIRELESS_CHARG: {
        key: 5,
        value: '无线充电座'
    },
    OTHER: {
        key: 6,
        value: '其他'
    }
}
// 桩类型
export const pileStatusEnum = {
    DIRECT: {
        key: 1,
        value: '直流'
    },
    COMMUNICATE: {
        key: 2,
        value: '交流'
    },
    AC_DC: {
        key: 3,
        value: '交直流一体设备'
    },
    WIRELESS: {
        key: 4,
        value: '无线设备'
    },
    OTHER: {
        key: 5,
        value: '其他'
    }
}
// 充电枪状态
export const gunStatusEnum = {
    IDLE: {
        key: 0,
        value: '空闲中'
    },
    INSERTED: {
        key: 1,
        value: '已插枪'
    },
    STARTING: {
        key: 2,
        value: '启动中'
    },
    CHARGING: {
        key: 3,
        value: '充电进行中'
    },
    NOT_PULLED: {
        key: 4,
        value: '充电完成后未拔枪'
    },
    RESERVATION: {
        key: 5,
        value: '预约状态'
    },
    SYS_ERROR: {
        key: 6,
        value: '系统故障'
    },
    DRAW_GUN: {
        key: 7,
        value: '拔枪'
    }
}
// 车位状态
export const partStatusEnum = {
    HOUSE_SOCKET: {
        key: -1,
        value: '未知'
    },
    COMMUNICATE_SOCKET: {
        key: 0,
        value: '空闲'
    },
    COMMUNICATE_PLUG: {
        key: 1,
        value: '占用'
    }
}
// 电子锁状态
export const electroniCLockStatusEnum = {
    CLOSE: {
        key: 0,
        value: '关闭'
    },
    OPEN: {
        key: 1,
        value: '开启'
    }
}
// 国家标准
export const nSStatusEnum = {
    ONE: {
        key: 1,
        value: 2011
    },
    TWO: {
        key: 2,
        value: 2015
    }
}
// 加入共享平台
export const shareStationEnum = {
    KUAIDIAN: {
        key: 1,
        value: '快电'
    },
}

// 合作商家支付状态
export const walletPayStatusEnum = {
    CLOSE: {
        key: -1,
        name: '失败'
    },
    FILE: {
        key: 0,
        name: '待支付'
    },
    OK: {
        key: 1,
        name: '提现成功'
    },
    FAILOK: {
        key: 2,
        name: '支付中'
    },
}
export const walletPayStatusMap = changeEnumToMap(walletPayStatusEnum)

// 合作商家审核状态
export const walletAuditStatusEnum = {
    NONE: {
        key: -10,
        value: ''
    },
    CLOSE: {
        key: -1,
        value: '审核失败'
    },
    FILE: {
        key: 0,
        value: '待审核'
    },
    OK: {
        key: 1,
        value: '审核通过'
    },
}
export const walletAuditStatusMap = changeEnumToMap2(walletAuditStatusEnum)

// 道闸平台状态
export const gateStateEnum = {
    CLOSE: {
        key: 0,
        name: '关闭'
    },
    OPEN: {
        key: 1,
        name: '正常'
    }
}
export const gateStateEnumMap = changeEnumToMap(gateStateEnum)

// 道闸上传状态
export const gateUpLogStateEnum = {
    CREATE: {
        key: 0,
        name: '创建'
    },
    UPLOADING: {
        key: 1,
        name: '上传中'
    },
    SUCCESS: {
        key: 2,
        name: '上传成功'
    },
    FAIL: {
        key: 3,
        name: '上传失败'
    }
}
export const gateUpLogStateEnumMap = changeEnumToMap(gateUpLogStateEnum)

export const payStatusEnum = {
    CREATE: {
        key: -1,
        name: '待支付'
    },
    FAIL: {
        key: 0,
        name: '支付失败'
    },
    SUCCESS: {
        key: 1,
        name: '已支付'
    }
}

export const auditStatusEnum = {
    CLOSE: {
        key: -1,
        name: '审核不通过'
    },
    FILE: {
        key: 0,
        name: '待审核'
    },
    OK: {
        key: 1,
        name: '审核通过'
    },
}
